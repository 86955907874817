<template>
  <es-table class="products" :headers="headers" :items="products" @update="getProductsPages">
    <div class="products__actions" v-if="hasRight('products.manage')">
      <v-btn class="evi-button-green evi-button-green--fill mr-3" @click="createProductModal = true">
        Создать продукт
      </v-btn>
    </div>
    <v-dialog
        v-model="createProductModal"
        max-width="612px"
    >
      <CreateProduct @close="createProductModal = false" @update="getProducts"/>
    </v-dialog>
  </es-table>
</template>

<script>
import {mapActions, mapState} from "vuex";
import CreateProduct from "../../components/products/CreateProduct";
import {hasRight} from '@/utils/access/hasRight';

export default {
  name: "Products",
  components: {CreateProduct},
  data: () => ({
    createProductModal: false,
    headers: [
      {
        text: 'ID',
        value: 'id',
      },
      {
        text: 'Название',
        value: 'name',
      },
      {
        text: 'Тип',
        value: 'type',
      },
      {
        text: 'Стоимость',
        value: 'price',
      },
    ],
  }),
  computed: {
    ...mapState("products", ["products",]),
  },
  methods: {
    ...mapActions({
      getProductsPages: 'products/getProductsPages',
    }),
    hasRight: hasRight,
    getProducts() {
      this.getProductsPages({
        per_page: 10,
        page: 1,
      });
    }
  },
}
</script>

<style lang="scss" scoped>
.products {
  &__actions {
    margin-left: 23px;
  }
}
</style>
