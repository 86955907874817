<template>
  <v-card class="modal-create">
    <v-form ref="form" v-model="valid">
      <v-card-title class="d-flex justify-center">
        <span class="text-h5 text-center mb-11">Создание продукта</span>
      </v-card-title>
      <v-card-text class="modal-create__form">
        <div class="close-dialog" @click="closeDialog">
          <img :src="require('@/assets/img/common/close-dialog.svg')"/>
        </div>
        <v-col
            cols="12"
        >
          <v-text-field
              class="evi-text-field"
              color="#44D370"
              v-model="name"
              :rules="nameRules"
              placeholder="Введите название продукта"
          >
            <template v-slot:label>
              Название
              <span class="important">*</span>
            </template>
          </v-text-field>
        </v-col>
        <v-col
            cols="12"
        >

          <v-select
              class="evi-select"
              color="#44D370"
              v-model="type"
              :items="types"
              item-text="name"
              item-value="value"
              label="Тип"
              item-color="green"
          >
            <template v-slot:append>
              <span class="evi-select__arrow">
                  <v-icon>mdi-chevron-down</v-icon>
              </span>
            </template>
            <template v-slot:label>
              Тип
              <span class="important">*</span>
            </template>
          </v-select>
        </v-col>
        <v-col
            cols="12"
        >
          <v-text-field
              class="evi-text-field"
              color="#44D370"
              v-model="weight"
              placeholder="Введите вес товара"
              v-if="type === 'product'"
              :rules="nameRules"
          >
            <template v-slot:label>
              Вес в граммах
              <span class="important">*</span>
            </template>
          </v-text-field>
        </v-col>
        <v-col
            cols="12"
        >
          <v-text-field
              class="evi-text-field"
              color="#44D370"
              v-model="price"
              placeholder="Укажите стоимость продукта"
              :rules="priceRules"
              v-mask="currencyMask"
          >
            <template v-slot:label>
              Стоимость
              <span class="important">*</span>
            </template>
          </v-text-field>
        </v-col>
      </v-card-text>
      <v-card-actions class="modal-create__actions">
        <v-btn class="evi-button-green evi-button-green--fill" @click="create">
          Создать продукт
        </v-btn>
      </v-card-actions>
    </v-form>
  </v-card>
</template>

<script>
import {mapActions, mapState} from "vuex";
import createNumberMask from 'text-mask-addons/dist/createNumberMask'

const currencyMask = createNumberMask({
  prefix: '',
  allowDecimal: true,
  includeThousandsSeparator: true,
  thousandsSeparatorSymbol: ' ',
  allowNegative: true,
});

export default {
  name: "CreateProduct",
  data: () => ({
    currencyMask,
    valid: true,
    type: '',
    name: '',
    weight: '0',
    price: '',
    nameRules: [(v) => !!v || 'Введите значение'],
    priceRules: [(v) => !!v || 'Укажите цену'],
  }),
  computed: {
    priceNum() {
      if (this.price) {
        let priceArray = this.price.split(" ");

        let sum = '';

        for (let i = 0; i < priceArray.length; i++) {
          sum += priceArray[i];
        }

        return parseFloat(sum);
      } else {
        return 0;
      }
    },
    ...mapState("products", ["types",]),
  },
  methods: {
    ...mapActions({
      createProduct: 'products/createProduct',
    }),
    validate() {
      return !!this.$refs.form.validate();
    },
    create() {
      if (this.validate()) {
        let payload = {
          name: this.name,
          price: this.priceNum,
          type: this.type,
        };
        if (this.type === 'product') {
          payload.weight = this.weight;
        }

        this.createProduct(payload).then(() => {
          this.$emit('update');
          this.$emit('close');
          this.name = '';
          this.price = null;
          this.type = '';
          this.$refs.form.resetValidation();
        });
      }
    },
    closeDialog() {
      this.$emit('close');
      this.name = '';
      this.price = null;
      this.type = '';
      this.$refs.form.resetValidation();
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
